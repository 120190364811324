<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap1ProgrammingAComputer"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 1: Programming A Computer" image-name="1-programming.jpg" image-alt="Programming A Computer"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>We will list the different hardware parts of a computer.</p></li>
<li><p>We will see what a program is and how it looks like.</p></li>
<li><p>We will understand how a program is loaded and executed.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Memory Unit, Arithmetic and Logic Unit, Input/Output, Control Unit</p></li>
<li><p>Central memory, Auxiliary memory</p></li>
<li><p>Volatile and non-volatile memory</p></li>
<li><p>RAM/ROM</p></li>
<li><p>CPU</p></li>
<li><p>High and low-level languages</p></li>
<li><p>Assembly language, assembler</p></li>
<li><p>Compiled and interpreted language</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>This book is about Go. Before jumping into our main subject, you need to know some basic knowledge about computers.</p>
<p>Experienced programmers can skip this chapter. Beginners should take some time to study it.</p>
<p>Your programs will run on hardware. Knowing how your hardware is working may improve the design of your programs.</p>
<p>Firstly we will describe the main components of a computer. Then we will see what a program is and how the machine handles it.</p>
<div id="the-four-hardware-components" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> The four hardware components <a href="#the-four-hardware-components"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A computer is composed of <strong>four</strong> main parts :</p>
<ul>
<li><p><strong>The memory unit (MU)</strong> where data and programs are stored.</p>
<ul>
<li>For instance, we can store into the memory unit the grades of a college class. We can also hold a program that will compute the class’s average grade.</li>
</ul></li>
<li><p>The <strong>arithmetic and logic unit (ALU)</strong>. Its role is to perform arithmetic and logical operations on data stored into the memory unit. This unit can perform, for instance, additions, incrementations, decrementations, which are called <strong>operations</strong>. In general, each operation requires two operands and output a result.</p>
<ul>
<li>Let’s say we want to add 5 and 4. Those two numbers are the operands. The result of this operation is 9. Operands are loaded from the memory unit. The ALU is an electrical circuitry that is designed to execute operations.</li>
</ul></li>
<li><p>The <strong>input and output unit (I/OU)</strong> will be in charge of loading data into the memory unit from an <strong>input</strong> device. This unit also sends data from the memory unit to an <strong>output</strong> device.</p>
<ul>
<li><p>An input device is, for example, the touchpad of your computer, your keyboard, your mouse.</p></li>
<li><p>An output device is, for instance, your monitor.</p></li>
</ul></li>
<li><p>The <strong>control unit (CU)</strong> will receive instructions from programs and will control the activity of the other units.</p></li>
</ul>
<p>The four hardware components represent a <strong>schematic view</strong> of the computer’s components.</p>
<div id="memory" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Memory <a href="#memory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A computer is composed of two types of memory :</p>
<ul>
<li><p>The central memory</p></li>
<li><p>The auxiliary memory</p></li>
</ul>
<p>Two categories of memory exist:</p>
<ul>
<li><p>Volatile</p></li>
<li><p>Non Volatile</p></li>
</ul>
<div id="the-central-memory" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> The central memory <a href="#the-central-memory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This type of memory is necessary to run the operating systems and all the other programs your computer will run. The central memory contains two types of storage :</p>
<ul>
<li><p><strong>RAM</strong> (Random Access Memory). This type of storage requires electric power to persist data. When you turn your computer off, the memory contained in this type of storage will be erased. The operating system and the programs you use will be loaded into this memory. This type of memory is <strong>volatile</strong>.</p></li>
<li><p><strong>ROM</strong> (Read-Only Memory). This is a memory that contains data necessary for the computer to run correctly. This kind of memory is <strong>not volatile</strong> (when you turn the computer off, it will not be erased). It’s designed to be only readable and not updated by the system.</p></li>
</ul>
<div id="the-auxiliary-memory" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> The auxiliary memory <a href="#the-auxiliary-memory"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>This type of memory is not volatile. When the power is going off, the data stored will not be erased. Here are some examples of auxiliary memory: Hard drives, USB keys, CD-ROM, DVD ...etc.</p>
<p>Read and writes to this type of memory is <strong>slow</strong> compared to the RAM.</p>
<p>Some hard drives sequentially access memory. The system should respect a particular sequence. Respecting this access sequence takes a longer time than a random access mode. Note that some hard drives allow random memory access.</p>
<div id="ssd-hard-drive" class="anchor"></div>
<h3 data-number="5.2.1"><span class="header-section-number">5.2.1</span> SSD hard drive <a href="#ssd-hard-drive"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Hard drives, also denoted Hard Disk Drive (HDD), are composed of magnetic disks that are rotating. Data are read and write thanks to a moving magnetic head. Reads and writes will generate a rotation and a magnetic head movement, which consumes time.</p>
<p>SSD (Solid-State Drives) are not constructed like that. There is no magnetic head neither magnetic disks. Instead, data is stored in flash memory cells. Data access is quicker on that kind of disk. Note that SSD also costs more than traditional electromagnetic hard drives.</p>
<div id="cpu" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> CPU <a href="#cpu"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>CPU is the initials of <strong>Central Processing Unit</strong>. The CPU is also denoted <strong>processor</strong>. The CPU contains :</p>
<ul>
<li><p>The <strong>arithmetic and logic unit (ALU)</strong></p></li>
<li><p><strong>The control unit (CU)</strong></p></li>
</ul>
<p>The CPU will be responsible for executing the instructions given by a program. For instance, the program can instruct to perform an addition between two numbers. Those numbers will be retrieved from the memory unit and passed to the ALU. The program might also require performing an I/O operation like reading data from the hard drive and loading it into the RAM for further processing. The CPU will execute those instructions.</p>
<p>The CPU is the central component of a computer.</p>
<div id="what-is-a-program" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> What is a program? <a href="#what-is-a-program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To make computers do something, we have to feed them with precise instructions. This set of instructions is called “program”.</p>
<p>Following a more official definition, a program is “a combination of computer instructions and data definitions that enable computer hardware to compute”<b-link class="citation" data-cites="institute1990ieee" href="#institute1990ieee" >[@institute1990ieee]</b-link>.</p>
<p>Let’s take an example. Imagine a program that asks the user to type two numbers. The program adds those numbers, and the result is then displayed on the monitor. The instructions that have to be written are :</p>
<ol type="1">
<li><p>Output “Please type your first number and press enter” on the monitor.</p></li>
<li><p>When a number is typed and the “Enter” key is pressed on the keyboard, store the number into memory. Let’s denote <span class="math inline">A</span> this number.</p></li>
<li><p>Output “Please type your second number and press enter” on the monitor.</p></li>
<li><p>When a number is typed and the “Enter” key is pressed on the keyboard, store the number into memory. Let’s denote <span class="math inline">B</span> this number.</p></li>
<li><p>Send to the ALU the two numbers (<span class="math inline">A</span> and <span class="math inline">B</span>) and the addition opcode and store the result into memory.</p></li>
<li><p>Output the result on the monitor</p></li>
</ol>
<p>Two types of instructions are performed :</p>
<ul>
<li><p><strong>I/O operations</strong> : retrieve numbers stored into memory, store numbers into memory from an the input device (the keyboard), load data from memory, and display it to the user.</p></li>
<li><p><strong>An arithmetic operation</strong> : add two numbers.</p></li>
</ul>
<p>We have here a set of instructions that are written in plain English. The machine does not understand English sentences. Those sentences need to be translated to a language that the machine understands. What is this language?</p>
<div id="how-to-speak-to-the-machine" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> How to speak to the machine? <a href="#how-to-speak-to-the-machine"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="programming-languages-are-formal-languages" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Programming languages are formal languages <a href="#programming-languages-are-formal-languages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Instructions that are given to the machine are written with programming languages. Programming languages are <strong>formal languages.</strong>They are composed of words that are constructed from an alphabet (a set of distinct characters). Those words are organized following specific <strong>rules</strong>. Go is a programming language, like x86 Assembly, Java, C, C++, Javascript...</p>
<p>They are two types of programming languages :</p>
<ul>
<li><p>Low level</p></li>
<li><p>High level</p></li>
</ul>
<p>Low-level programming languages are closer to the processing unit’s instructions. Higher-level languages provide constructs that make them easier to learn and to use on a day-to-day basis.</p>
<p>Some high-level languages are compiled, others are interpreted, and some are in between. We will see in the next sections what those two terms mean.</p>
<figure>
<b-img :src="require('@/assets/images/programming_languages_lowHighCompInterp.png')" alt="Programming languages classification"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Programming languages classification</figcaption>
</figure>
<div id="machine-language" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Machine language <a href="#machine-language"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To speak to the processing unit of a computer, we can use a <strong>machine language</strong>. Machine languages are composed exclusively of zeros and ones. An instruction written in a machine language is a suite of 0 and 1. Each processor (or family of processors) will define a list of instructions called the <strong>instruction set</strong>. There is an instruction to add to the number, increment by one, decrement by one, copy data from one location in memory to another place...etc.</p>
<p>It’s possible to write computer programs directly into machine language. However, this is not easy.</p>
<div id="assembly-language" class="anchor"></div>
<h2 data-number="8.3"><span class="header-section-number">8.3</span> Assembly language <a href="#assembly-language"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The assembly language is a <strong>low-level programming language</strong>. The instructions of a program written in an assembly language correspond to machine instructions. Assembly languages use mnemonics which are small words that will correspond to a machine instruction. For instance <span class="math inline">MOV</span> Will instruct the machine to move data from one location to another location. Developers can also comment the code (which is not possible with machine language).</p>
<p>To create a program in assembly language, the developer will write instruction to one or several files. Those files are named <strong>source files</strong>.</p>
<p>Here is an example of an instruction written in x86 Linux assembly :</p>
<pre v-highlightjs><code class="go" v-pre >// assembly code
mov    eax,1
int    0x80</code></pre>
<p>Those two lines will perform a system call that will close the program (the “1” represent the system call number that means “exit the program”). Note that assembly language is different from machine to machine. We say that it’s <strong>machine-specific</strong>.</p>
<p>An <strong>assembler</strong> is used to convert the source files written in an assembly language to object code files. We say that we <strong>assemble</strong> the program. The linker will then transform those object code files into an executable file. An executable file contains all the computer’s necessary instructions to launch the program.</p>
<figure>
<b-img :src="require('@/assets/images/assembly_to_executable.png')" alt="From assembly code to an executable"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">From assembly code to an executable</figcaption>
</figure>
<div id="high-level-languages" class="anchor"></div>
<h2 data-number="8.4"><span class="header-section-number">8.4</span> High-level languages <a href="#high-level-languages"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>There are plenty of high-level languages on the market, like Go. Those languages are not closely bound to machine architecture. They offer a convenient way to write instructions. For instance, if we want to make a system call to exit our program, we can write in go :</p>
<pre v-highlightjs><code class="go" v-pre >os.Exit(1)</code></pre>
<p>With the C language, we can write :</p>
<pre v-highlightjs><code class="go" v-pre >// C Code
exit(1)</code></pre>
<p>With Java, we can write :</p>
<pre v-highlightjs><code class="go" v-pre >// Java Code
System.exit(1);</code></pre>
<p>In this example, we do not have to move a number into a register; we use the languages constructs (functions, packages, methods, variables, types ...). The objective of this book is to give you precise and concise definitions of those tools to build Go applications.</p>
<p>High-level programs are also written to files. Files are named “source files”. Generally, programming languages require adding a specific extension to the filename. For Go programs we will add “.go” At the end of each file that we will write. In PHP the extension is “.php”.</p>
<p>When source files are written, the program that they define cannot be executed immediately. The source file needs to be <strong>compiled</strong> by using a <strong>compiler</strong>. The compiler will transform source files into an executable. The compiler is also a program. Go is part of the compiled language family.</p>
<figure>
<b-img :src="require('@/assets/images/compiler.png')" alt="Go is a compiled language"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Go is a compiled language</figcaption>
</figure>
<div id="compiled-vs.-interpreted" class="anchor"></div>
<h3 data-number="8.4.1"><span class="header-section-number">8.4.1</span> Compiled vs. Interpreted <a href="#compiled-vs.-interpreted"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Note that some programming languages are interpreted. When the source files have been written, the programmer does not need to compile the sources. With the source files ready, the system can execute the program thanks to an interpreter. Each instruction written into the source file is translated and executed by the interpreter. In some cases, interpreters are storing in a cache a compiled version of the program to boost performance (the source files are not translated each time). PHP, Python, Ruby, Perl are interpreted languages.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Where are programs stored?</p></li>
<li><p>Reading data from a hard drive is slower than reading data from RAM. True or false?</p></li>
<li><p>Can you write into the ROM? True or false?</p></li>
<li><p>What are the two types of memory?</p></li>
<li><p>What is the definition of “volatile memory”?</p></li>
<li><p>Which program transforms code written assembly language into object code?</p></li>
<li><p>Which program transforms object code into an executable file?</p></li>
<li><p>Give two advantages of high-level languages compared to low-level languages?</p></li>
<li><p>Go is an interpreted language? True or false?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p><strong>Where are programs stored?</strong></p>
<ol type="1">
<li>Into the memory unit (MU)</li>
</ol></li>
<li><p><strong>Reading data from a hard drive is slower than reading data from RAM. True or false?</strong></p>
<ol type="1">
<li>True. Fetching and writing data to the RAM is blazing fast, whereas accessing data stored into hard drives generally takes longer.</li>
</ol></li>
<li><p><strong>A computer user can write ROM? True or false?</strong></p>
<ol type="1">
<li>False. This type of memory can only be read. It is used to store the OS (Operating system)</li>
</ol></li>
<li><p><strong>What are the two types of memory?</strong></p>
<ol type="1">
<li><p>The central memory</p></li>
<li><p>The auxiliary memory</p></li>
</ol></li>
<li><p><strong>What is the definition of “volatile memory” ?</strong></p>
<ol type="1">
<li>Volatile memory will be erased when the computer is switched off.</li>
</ol></li>
<li><p><strong>Which program transforms code written assembly language into object code?</strong></p>
<ol type="1">
<li>The assembler will take as input assembly language code and generate machine code.</li>
</ol></li>
<li><p><strong>Which program transforms object code into an executable file?</strong></p>
<ol type="1">
<li>The linker</li>
</ol></li>
<li><p><strong>Give two advantages of high-level languages compared to low-level languages?</strong></p>
<ol type="1">
<li><p>They offer high-level constructs that are easier to use.</p></li>
<li><p>The code will not be specific to the technical architecture of a machine. We say that the code is portable.</p></li>
</ol></li>
<li><p><strong>Go is an interpreted language? True or false?</strong></p>
<ol type="1">
<li>False: Go is a compiled language</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>At the macro-level, a computer is composed of :</p>
<ul>
<li><p>A memory unit (MU): to store data and programs</p></li>
<li><p>An Arithmetic and Logical Unit (ALU): to perform computation</p></li>
<li><p>An Input and Output Unit (IOU): to manage input devices and output devices.</p></li>
<li><p>A Control Unit (CU) will manage the MU, ALU, and IOU following the instruction given by the program executed</p></li>
</ul></li>
<li><p>CPU means Central Processing Unit (also called processor or microprocessor) is composed of the ALU and CU.</p></li>
<li><p>A program is a set of instructions.</p></li>
<li><p>Developers write programs with a programming language.</p></li>
<li><p>Programming languages are composed of words and characters that must be ordered following specified rules.</p></li>
<li><p>They are high and low-level programming languages.</p></li>
<li><p>Machine language and assembly language are low-level. The instructions written in those languages are closely linked to the hardware organization and capabilities. They provide little abstractions.</p></li>
<li><p>Go is a high-level programming language that is compiled.</p></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap1ProgrammingAComputer"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Foreword'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Foreword</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap2TheGoLanguage'}">
									<p><u><small>Next</small></u></p>
									<p><small>The Go Language</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Programming A Computer - Practical Go Lessons"
const description = "Your programs will run on hardware. Knowing how your hardware is working may improve the design of your programs. Firstly we will describe the main components of a computer. Then we will see what a program is and how the machine handles it."

export default {
  name: "Chap1ProgrammingAComputer",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

mounted() {
    const mathElements = window.document.getElementsByClassName("math");
    const macros = [];
    for (var i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;
      if (mathElements[i].tagName === "SPAN") {
 		window.katex.render(texText.data, mathElements[i], {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: true,
          macros: macros,
          fleqn: false
        });
      }
    }
  },

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
